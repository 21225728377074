<template>
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 512 512"
    style="enable-background:new 0 0 512 512;"
    xml:space="preserve"
  >
    <path
      style="fill:#FCD589;"
      d="M427.046,88.692l-145.225-0.139c-12.89-0.012-23.344,10.43-23.355,23.309v0.635
	c-0.023,12.89,10.419,23.344,23.309,23.355l60.987,0.046L207.216,271.445c-8.559,8.547-8.559,22.42,0,30.978
	c8.547,8.547,22.42,8.547,30.979,0l-19.509,19.497c-8.559,8.559-8.559,22.431,0,30.979c4.285,4.274,9.887,6.422,15.489,6.422
	c5.602,0,11.204-2.148,15.489-6.422l24.152-24.152c-8.559,8.559-8.559,22.42,0,30.978c4.274,4.285,9.876,6.422,15.489,6.422
	c2.818,0,5.625-0.543,8.282-1.617c2.622-1.063,5.082-2.68,7.207-4.805l31.36-31.36c-4.285,4.274-6.422,9.876-6.422,15.478
	c0,0.993,0.069,1.998,0.196,2.992c0.635,4.574,2.703,8.986,6.226,12.498c4.285,4.285,9.887,6.422,15.489,6.422
	s11.204-2.137,15.489-6.422l84.353-84.353l21.161-21.149c22.2-22.2,36.095-51.354,39.353-82.586L427.046,88.692z"
    />
    <g>
      <path
        style="fill:#EDC57F;"
        d="M329.733,343.843c0,4.147,1.178,8.293,3.523,11.909l62.88-62.879c3.384-3.384,3.384-8.871,0-12.255
		l0,0c-3.384-3.384-8.871-3.384-12.255,0.001l-47.724,47.735C331.881,332.628,329.733,338.23,329.733,343.843z"
      />
      <path
        style="fill:#EDC57F;"
        d="M215.787,349.307L336.493,228.6c3.383-3.383,3.382-8.87-0.003-12.252l0,0
		c-3.383-3.38-8.865-3.379-12.246,0.003l-86.061,86.061l-19.497,19.509C211.236,329.359,210.277,340.817,215.787,349.307z"
      />
      <path
        style="fill:#EDC57F;"
        d="M267.395,344.236c0,4.147,1.167,8.293,3.523,11.909l88.469-88.469c3.384-3.384,3.384-8.871,0-12.255
		l0,0c-3.384-3.384-8.871-3.384-12.255,0l-73.326,73.326C269.531,333.02,267.395,338.622,267.395,344.236z"
      />
      <path
        style="fill:#EDC57F;"
        d="M439.56,181.379c2.002,4.724-0.512,10.178-5.423,11.663c-8.431,2.547-17.008,3.775-25.479,3.775
		c-32.411,0-63.366-17.857-78.913-47.9l13.017-13.017c14.346,33.894,51.952,50.971,86.416,40.545
		C433.376,175.174,437.849,177.341,439.56,181.379L439.56,181.379z"
      />
    </g>
    <path
      style="fill:#FCD589;"
      d="M304.795,328.747L191.508,215.459l-5.625-5.614c-6.145-6.145-1.79-16.656,6.896-16.656l37.1-0.023
	c13.052-0.012,23.632-10.603,23.632-23.655s-10.592-23.644-23.655-23.644c-0.012,0-0.012,0-0.023,0l-144.878,0.139L0,228.546
	c3.269,31.233,17.164,60.386,39.364,82.586l21.149,21.149l84.365,84.365c8.547,8.547,22.42,8.547,30.967,0
	c1.109-1.109,2.079-2.31,2.899-3.581c2.345-3.615,3.523-7.762,3.523-11.909c0-5.614-2.148-11.216-6.422-15.489l31.371,31.36
	c8.547,8.559,22.42,8.559,30.978,0c1.109-1.109,2.079-2.31,2.888-3.569c2.356-3.615,3.523-7.762,3.523-11.909
	c0-5.614-2.137-11.216-6.411-15.489l24.152,24.152c8.547,8.547,22.42,8.547,30.967,0v-0.012c1.109-1.109,2.079-2.31,2.899-3.581
	c5.51-8.49,4.551-19.948-2.899-27.386l-19.497-19.509c8.547,8.559,22.42,8.559,30.978,0c1.109-1.109,2.079-2.31,2.899-3.581
	c2.345-3.615,3.511-7.762,3.511-11.909C311.206,338.634,309.069,333.032,304.795,328.747z"
    />
    <g>
      <path
        style="fill:#EDC57F;"
        d="M182.267,401.157c0,4.147-1.178,8.293-3.523,11.909l-62.88-62.879
		c-3.384-3.384-3.384-8.871,0-12.255l0,0c3.384-3.384,8.871-3.384,12.255,0.001l47.724,47.735
		C180.119,389.941,182.267,395.543,182.267,401.157z"
      />
      <path
        style="fill:#EDC57F;"
        d="M296.213,406.62L175.507,285.914c-3.383-3.383-3.382-8.87,0.003-12.252l0,0
		c3.383-3.38,8.865-3.379,12.246,0.003l86.061,86.061l19.497,19.509C300.764,386.673,301.723,398.131,296.213,406.62z"
      />
      <path
        style="fill:#EDC57F;"
        d="M311.206,344.236c0,4.147-1.167,8.293-3.511,11.909L192.727,241.178
		c-8.21-8.21-10.882-20.453-6.839-31.337l0,0l118.907,118.906C309.069,333.032,311.206,338.634,311.206,344.236z"
      />
      <path
        style="fill:#EDC57F;"
        d="M244.605,401.551c0,4.147-1.167,8.293-3.523,11.909l-88.469-88.469
		c-3.384-3.384-3.384-8.871,0-12.255l0,0c3.384-3.384,8.871-3.384,12.255,0l73.326,73.326
		C242.469,390.334,244.605,395.936,244.605,401.551z"
      />
    </g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
</template>

<script>
export default {};
</script>

<style></style>
